import styled from 'styled-components';

export const TitleContainer = styled.div`
  background: #fff url(${(props) => props.$bgImage}) repeat-x bottom center;
  height: 500px;
  @media (max-width: 991px) {
    background-size: cover;
    height: 300px;
  }
`;

export const Title = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 65px 0px 0px 0px;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    padding: 45px 35px 10px 35px;
    text-align: center;
  }
`;

export const TitleDesktopImage = styled.img`
  max-width: 540px;
  height: auto;
  @media (max-width: 991px) {
    display: none !important;
  }
`;

export const TitleMobileImage = styled.img`
  display: none;
  @media (max-width: 991px) {
    display: block;
    max-width: 310px;
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 30px;
    margin: 0 auto;
  }
`;
